"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'Digital SDP',
    organizationId: 'uoyj5diUfVw9Sd7d2dGT',
    version: '1.0',
    ionicAppId: 'deab7b82',
    applicationKey: 'sdp',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.sdp',
        appId: '1617203744',
    },
    android: {
        bundleId: 'com.utoniq.sdp',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: false,
            trade: true,
            membership: true,
            thread: false,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
        ],
        globalTabs: ['home', 'notifications', 'artist', 'myProfile'],
        artistTabs: ['home', 'collections', 'store', 'gachas', 'trade'],
    },
    deeplinkUrl: 'https://www.digital-sdp.com',
    applink: 'sdp.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3562292',
    storagePath: 'sdp',
    dynamicLinkPrefix: 'digitalsdp',
};
exports.default = appConfig;
